<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center fondoM1">
        <v-row class="fondoM2"  justify="center" no-gutters>
            <v-col cols="12" md="11">
                <menus/>
            </v-col>
            <v-col cols="12" md="11" class="py-0 px-0 fondoFAQs" >
                <v-responsive  :aspect-ratio="9/13" align="center" max-width="700" height="100%" class="justify-center  text-center mx-auto align-start">
                    <h1 class="mb-10 mt-10 white--text">FAQ's</h1>
                    <v-expansion-panels dark flat >
                        <v-expansion-panel
                        v-for="(item,i) in 5"
                        :key="i"
                        style="background-color: rgba(0,0,0,0);"
                        >
                            <v-divider class="becks" />
                            <v-expansion-panel-header>
                                <h3>¿DEBO SER MAYOR DE EDAD PARA PARTICIPAR?</h3>
                                <template v-slot:actions>
                                <v-icon color="becks">
                                $expand
                                </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content align="left">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Si es necesario quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        
                    </v-expansion-panels>
                    <v-divider class="becks"/>
                </v-responsive>
                </v-col>
            </v-row>

    </v-container>
</template>

<script>
    import menus from '../components/menus.vue'

    export default {
        data: () => ({
            dialog: false,
        }),
        methods: {
            
        },
        computed:{

        },
        name: 'FAQs',
        components: {
            menus
        },
    }
</script>
    
<style scoped>
    @media (min-width: 961px){
        .fondoFAQs{
            background-image: url("../assets/FAQs.png");
            background-size: cover;
        }
    }
    @media (max-width: 960px){
        .fondoM2{
            background-image: url("../assets/FAQs1.png");
            background-size: 30%;
            background-position-x: right;
            background-position-y: top;
        }
        .fondoM1{
            background-image: url("../assets/FAQs2.png");
            background-size: 80%;
            background-position-x: left;
            background-position-y: bottom;
        }
    }
</style>

